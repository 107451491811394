<template>
  <div
    class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto min-h-full modal-container"
    :class="{ hidden: hidden }"
  >
    <div
      ref="modalContent"
      class="relative transform overflow-hidden rounded-lg bg-white px-2 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
    >
      <div class="bg-white rounded-md font-medium px-2">
        <div
          class="absolute top-0 right-0 pt-4 pr-4 sm:block"
          v-if="allowClose"
        >
          <button
            type="button"
            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="close"
          >
            <span class="sr-only">Close</span>
            <svg
              class="h-6 w-6"
              x-description="Heroicon name: outline/x-mark"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div class="mt-2">
          <slot name="content"></slot>
        </div>

        <div class="mt-4 flex flex-row-reverse">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "@/utils/storage";

export default {
  name: "SimpleModal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    trackId: {
      type: String,
      default: null,
    },
    allowClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hidden: true,
    };
  },
  watch: {
    currentUser: {
      handler() {
        if (this.currentUser) {
          this.init();
        }
      },
      immediate: true,
    },
  },
  async beforeDestroy() {
    await this.setTrack();
    document.body.classList.remove("lock");
    if (!this.allowClose) {
      return;
    }

    document.body.removeEventListener("click", this.$el.clickOutsideEvent);
  },
  computed: {
    parsedTrackId() {
      if (!this.trackId) {
        return null;
      }

      return `modal:${this.currentUser.id}:${this.trackId}`;
    },
  },
  methods: {
    async init() {
      const viewed = await this.alreadyViewed();
      if (viewed) {
        this.close();
      } else {
        this.setup();
      }
    },
    async alreadyViewed() {
      if (!this.parsedTrackId) {
        return false;
      }

      const viewed = await Storage.get(this.parsedTrackId);
      return viewed === "1";
    },
    setup() {
      this.hidden = false;
      document.body.classList.add("lock");
      if (!this.allowClose) {
        return;
      }

      this.$el.clickOutsideEvent = (event) => {
        if (
          !(
            this.$refs.modalContent == event.target ||
            this.$refs.modalContent.contains(event.target)
          )
        ) {
          this.clickedOutside();
        }
      };
      document.body.addEventListener("click", this.$el.clickOutsideEvent);
    },
    clickedOutside() {
      this.close();
    },
    async close() {
      await this.setTrack();

      this.$emit("close");
    },
    async setTrack() {
      if (this.parsedTrackId) {
        await Storage.set(this.parsedTrackId, "1");
      }
    },
  },
};
</script>
<style scoped>
.modal-container {
  padding-top: calc(20px + var(--safe-area-inset-top));
  background-color: rgba(229, 231, 235, 0.7);
}
</style>
