import Api from "@/api"
import normalize from "@/utils/jsonapi-normalize"

export const communications = {
  namespaced: true,
  state: {
    records: [],
    record: {},
    errors: [],
  },
  getters: {
    records: (state) => state.records,
  },
  actions: {
    async fetchAll({ commit }, options = {}) {
      try {
        const { data } = await Api.getCommunications(options)

        commit("setCommunications", normalize(data))
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async fetch({ commit }, { id, payload }) {
      try {
        const { data } = await Api.getCommunication(id, payload)

        commit("setCommunication", normalize(data))
      } catch (e) {
        commit("setErrors", e)
      }
    },
  },
  mutations: {
    setCommunications(state, data) {
      state.records = data
    },
    setCommunication(state, data) {
      state.record = data
    },
    setErrors(state, data) {
      state.errors = data.message
    },
  },
}
