<template>
  <div class="off-modal fixed z-50 left-0 right-0 top-0 h-full bg-white" v-show="visible">
    <div class="menu text-white">
      <div class="menu-container flex items-center h-full px-5">
        <div class="w-20 text-sm text-left">
          <button @click="$emit('cancel')">
            <span>{{ $t('app.cancel') }}</span>
          </button>
        </div>
        <div class="flex-1 text-center overflow-ellipsis overflow-hidden truncate">
          <span class="font-medium">{{ title }}</span>
        </div>
        <div class="w-20 text-sm text-right">
          <button @click="$emit('submit')" v-show="showSubmit">
            <span>{{ $t('buttons.submit') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="h-full overflow-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      default: null
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    visible() {
      if(this.visible) {
        this.lock()
      } else {
        this.unlock()
      }
    }
  },
  mounted() {
    if(this.visible) {
      this.lock()
    }
  },
  beforeDestroy() {
    this.unlock()
  },
  methods: {
    lock() {
      document.body.classList.add("lock")
    },
    unlock() {
      document.body.classList.remove("lock")
    }
  }
}
</script>

<style lang="scss" scoped>
.off-modal .menu {
  height: calc(40px + var(--safe-area-inset-top));
  background-color: var(--primary-color);

  .menu-container {
    padding-top: var(--safe-area-inset-top);
  }
}

.off-modal {
  padding-bottom: calc(16px + var(--safe-area-inset-bottom));
}
</style>
