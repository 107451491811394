<template>
  <DatePicker
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :input-class="context.attributes.class"
    :data-type="context.type"
    v-model="date"
    :options="context.options"
    v-bind="context.attributes"
    @blur="context.blurHandler"
    :attributes="attributes"
    :mode="mode"
    locale="pt"
    is24hr
  >
    <template v-slot="{ inputValue, togglePopover }">
      <input
        :class="context.attributes.class"
        :value="inputValue"
        @mousedown.prevent="togglePopover()"
      />
    </template>
  </DatePicker>
</template>

<script>
import { DatePicker } from "v-calendar/lib/v-calendar.umd";

export default {
  name: "VueFormulateDatapicker",
  components: {
    DatePicker,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: this.context.model,
      attributes: [
        {
          highlight: {
            class: "text-primary",
          },
        },
      ],
    };
  },
  watch: {
    date() {
      this.context.model = this.date.toISOString();
    },
  },
  methods: {
    parseHoursAndMinutes(value) {
      return (value < 10 ? "0" : "") + value;
    },
  },
  computed: {
    mode() {
      return this.context.attributes.mode || "date";
    },
  },
};
</script>
