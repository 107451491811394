// import { codePush, InstallMode } from 'capacitor-codepush';
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
class AppUpdater {
  constructor() {
    this.isChecking = false
  }

  async start() {
    console.debug("updater start", this.isChecking)

    if (!Capacitor.isPluginAvailable('CapacitorUpdater')) { return }
    if (this.isChecking) { return }

    this.isChecking = true

    try {
      const latest = await CapacitorUpdater.getLatest()
      console.debug("latest", latest)

      if (latest.url) {
        const data = await CapacitorUpdater.download({
          url: latest.url,
          version: latest.version,
        })

        SplashScreen.show()

        try {
          await CapacitorUpdater.set({ id: data.id })
        } catch(e) {
          console.debug("error update", e)
        }
      }
    } catch(e) {
      console.debug("error update", e)
    }

    this.isChecking = false
    this.ready()
  }

  ready() {
    if (!Capacitor.isPluginAvailable('CapacitorUpdater')) { return }
    console.debug("called update ready")

    SplashScreen.hide()
    CapacitorUpdater.notifyAppReady();
  }
}

export default new AppUpdater()
