import { format } from "fecha"
import TimeAgo from "javascript-time-ago"

export default {
  methods: {
    convertDateToLocale(dateStr, mask) {
      const date = new Date(dateStr)
      mask ||= "DD/MM/YYYY"

      return format(date, mask)
    },
    // locale
    timeAgo(date, lang = "pt-PT") {
      const timeAgo = new TimeAgo(lang)

      if (date) {
        return timeAgo.format(new Date(date))
      }
    },
    dateLocaleString(date, lang = "pt-PT") {
      const newDate = new Date(date)
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      }

      return newDate.toLocaleDateString(lang, options)
    },
    timeLocaleTimeString(date, lang = "pt-PT") {
      return new Date(date).toLocaleTimeString(lang, {
        hour: "2-digit",
        minute: "2-digit",
      })
    },
  },
}
