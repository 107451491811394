export default {
  methods: {
    nameOrEmailDisplay(user) {
      if (user?.name == undefined && user?.email == undefined) {
        return
      }

      if (user?.name == undefined) {
        return user.email
      }

      return user.name
    },
  },
}
