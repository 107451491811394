<template>
  <button
    class="relative flex items-center font-bold h-13 rounded-lg justify-center w-full"
    :class="classes"
    :disabled="isDisabled"
    v-on="$listeners"
    :autofocus="focus"
  >
    <div
      class="spinner-container absolute rounded-md w-full h-full flex items-center justify-center z-10"
    >
      <div class="relative">
        <div class="circle"></div>
      </div>
    </div>
    <div class="slot-container flex items-center">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "success",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      const obj = {}
      obj[this.type] = true
      obj["loading"] = this.loading

      return obj
    },
    isDisabled() {
      return this.disabled || this.loading
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  @apply text-white bg-primary active:opacity-80 transition-colors duration-700 p-3;
}

button.link {
  @apply text-current bg-transparent p-0;
}

button.light {
  @apply text-primary bg-primary-light;
}

button.danger {
  @apply bg-primary-danger;
}

button.secondary {
  @apply bg-gray-100 text-gray-400 text-red-700;
}

button.add {
  @apply bg-white;
}

button:disabled {
  @apply cursor-not-allowed;
  @apply bg-gray-100 text-gray-400;
}

.spinner-container {
  @apply opacity-0 transition-opacity pointer-events-none duration-200;
}

button.loading .spinner-container {
  @apply opacity-100 pointer-events-auto;
}

.slot-container {
  @apply opacity-100 transition-opacity duration-200;
}

button.loading .slot-container {
  @apply opacity-0;
}

.circle {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: currentColor;
  animation: spin 0.7s infinite linear;
}
</style>
