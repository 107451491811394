<template>
  <div class="relative">
    <label for="file-upload" class="custom-file-upload">
      <input
        id="file-upload"
        ref="input"
        type="file"
        @change="trigger"
        :accept="accept"
        v-if="!android"
      />
    </label>

    <div class="input" v-if="android" @click="takePicture"></div>
    <div class="absolute bottom-0 left-0.5 right-0.5" v-show="progress != 0">
      <div class="relative">
        <div class="overflow-hidden h-1 text-xs flex rounded-lg rounded-t-none">
          <div
            :style="{ width: progress + '%' }"
            class="transition-width shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary"
          ></div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import S3Uploader from "@/utils/s3-uploader"
import Api from "@/api"
import { Camera, CameraResultType } from "@capacitor/camera"
import { Capacitor } from "@capacitor/core"

export default {
  name: "Upload",
  props: {
    value: {},
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      progress: 0,
      android: Capacitor.getPlatform() === "android",
    }
  },
  watch: {
    progress() {
      if (this.progress == 100) {
        setTimeout(() => {
          this.progress = 0
          this.$refs.input.value = null
        }, 600)
      }
    },
  },
  methods: {
    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      })

      const blobData = this.b64toBlob(
        image.base64String,
        `image/${image.format}`
      )
      const name = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 10)
      const file = new File([blobData], name, {
        type: `image/${image.format}`,
      })
      this.trigger({ target: { files: [file] } })
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    trigger(event) {
      const file = event.target.files[0]
      S3Uploader(file, this.onProgress, this.onError, {
        presign: Api.createUploadSignature,
      }).then((r) => {
        const data = {
          id: r.name,
          url: r.url,
          storage: "cache",
          metadata: {
            filename: r.name,
            size: file.size,
            mime_type: file.type,
          },
        }
        this.$emit("input", data)
      })
    },
    onProgress(value) {
      this.progress = value
    },
    onError(value) {
      console.log("error", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-file-upload {
  @apply block opacity-0 absolute inset-0 z-10 w-full h-full;
}

label > input[type="file"] {
  display: none;
}

.input {
  @apply opacity-0 absolute inset-0 z-10 w-full h-full;
}

.transition-width {
  transition: width 0.5s ease-in-out;
}
</style>
