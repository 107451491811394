const euroFormmatter = Intl.NumberFormat('en-DE', {
  style: 'currency',
  currency: 'EUR',
});

export default {
  methods: {
    priceFormat(price) {
      return euroFormmatter.format(price)
    }
  }
}
