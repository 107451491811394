import Api from "@/api"
import Storage from "@/utils/storage"

export const auth = {
  namespaced: true,
  state: {
    status: "",
    token: "",
    user: {},
    permissions: [],
    permissionsFetched: false,
    permissionsVersion: null
  },
  getters: {
    isLoggedIn: (state) => state.token != null && state.token !== "",
    authStatus: (state) => state.status,
    profileComplete: (state) => state.user.profile_complete || false,
  },
  actions: {
    init({ commit }, data) {
      commit("init", data)
    },
    setToken({ commit }, data) {
      commit("loginSuccess", data)
    },
    login({ commit }, args) {
      const type = args.type

      let api_service = null

      if (type == "facebook") {
        api_service = Api.facebookLogin
      } else if (type == "google") {
        api_service = Api.googleLogin
      } else if (type == "apple") {
        api_service = Api.appleLogin
      }

      return api_service(args).then(
        (resp) => {
          const data = resp.data.data
          commit("loginSuccess", data)
          this.commit("global/clearStore")
          return Promise.resolve(data)
        },
        (error) => {
          commit("loginFailure")
          return Promise.reject(error)
        }
      )
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout")
        resolve()
      })
    },
    setUser({ commit }, data) {
      commit("setUser", data)
    },
    setUserAdmin({ commit }, data) {
      commit("setUserAdmin", data)
    },
    setLocale({ commit }, data) {
      commit("setLocale", data)
    },
    fetchPermissions( { commit , state}) {
      if(state.permissionsFetched) {
        return
      }

      return Api.fetchPermissions().then(({ data }) => {
        commit("fetchPermissions", data)
      })
    },
    fetchNewPermissions( { commit }) {
      return Api.fetchPermissions().then(({ data }) => {
        commit("fetchPermissions", data)
      })
    }
  },
  mutations: {
    loginRequest(state) {
      state.status = "loading"
    },
    init(state, data) {
      state.token = data.token
      state.user = data.user
    },
    async loginSuccess(state, data) {
      state.status = "success"
      state.token = data.attributes.jwt
      state.user = data.attributes.user
      await Storage.set("token", state.token)
      await Storage.set("user", JSON.stringify(state.user))
    },
    async setUser(state, data) {
      state.user = data
      await Storage.set("user", JSON.stringify(state.user))
    },
    loginFailure(state) {
      state.status = "error"
      state.user = null
    },
    async logout(state) {
      await Storage.remove("user")
      await Storage.remove("token")

      state.status = ""
      state.token = ""
      state.user = {}
    },
    async setUserAdmin(state, data) {
      state.user = Object.assign(state.user, { admin: data })
      await Storage.set("user", JSON.stringify(state.user))
    },
    async setLocale(state, data) {
      state.user = Object.assign(state.user, { locale: data })
      await Storage.set("user", JSON.stringify(state.user))
    },
    fetchPermissions(state, data) {
      state.permissionsVersion = data.version
      state.permissions = data.permissions
      state.permissionsFetched = true
    },
  },
}
