<template>
  <div>
    <SimpleModal :open="true" @close="$emit('close', object.id)">
      <template #content>
        <div class="flex flex-col items-center justify-center">
          <div class="mt-2 text flex items-center">
            <span class="text-primary mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none" class="w-6 h-6 shockwave">
                <path fill="currentColor"
                  d="M14.55 0H3.45C1.55 0 .15 1.5.15 3.4v16.2c0 1.9 1.4 3.4 3.3 3.4h11.1c1.8 0 3.3-1.6 3.3-3.4V3.4c0-1.9-1.4-3.4-3.3-3.4Zm-7.8 18.8h-1.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h1.5c.9 0 1.5.6 1.5 1.5s-.8 1.5-1.5 1.5Zm0-5.7h-1.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h1.5c.9 0 1.5.6 1.5 1.5s-.8 1.5-1.5 1.5Zm0-5.9h-1.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h1.5c.9 0 1.5.6 1.5 1.5s-.8 1.5-1.5 1.5Zm6 11.6h-1.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h1.5c.9 0 1.5.6 1.5 1.5s-.8 1.5-1.5 1.5Zm0-5.7h-1.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h1.5c.9 0 1.5.6 1.5 1.5s-.8 1.5-1.5 1.5Zm0-5.9h-1.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5h1.5c.9 0 1.5.6 1.5 1.5s-.8 1.5-1.5 1.5Z" />
              </svg>
            </span>
            Novo alerta de tomas disponível
          </div>
          <div class="mt-4 text-sm text-gray-500 text-center">
            Recebeu um novo alerta de tomas para o medicamento <strong>{{ object.attributes.name }}</strong>
            <div class="mt-2 px-4 text-left flex flex-col">
              <span v-if="object.attributes.quantity">{{ object.attributes.quantity }} unidades</span>
              <span class="mt-1" v-if="object.attributes.description">{{ object.attributes.description }}</span>
              <span class="mt-4 text-xs">Todos os dias, 2x por dia durante 2semanas</span>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="w-full">
          <Button @click="acceptDosage" :loading="submitLoading" :disabled="submitLoading">Aceitar</Button>
          <span class="mt-1 text-xs text-gray-400 text-center block">ao aceitar irá ser notificado para a toma do medicamento no horário definido</span>
        </div>
      </template>
    </SimpleModal>
  </div>
</template>

<script>
import SimpleModal from "@/components/SimpleModal"
import Button from "@/components/Button"
import DosageNotificationService from "@/utils/DosageNotificationService";

export default {
  name: "DosageCreate",
  props: {
    object: {
      required: true
    }
  },
  data() {
    return {
      date: null,
      submitLoading: false
    }
  },
  created() {
    this.date = new Date()
  },
  components: {
    SimpleModal,
    Button
  },
  methods: {
    async acceptDosage() {
      this.submitLoading = true

      await this.$store.dispatch("dosages/fetchDosages")
      await DosageNotificationService.activate(this.object)

      this.submitLoading = false
      this.$emit("close")
    },
  }
}
</script>
