import Api from "@/api";
import updateConfig from "@/utils/update-config";
import Storage from "@/utils/storage"
import { StatusBar } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

export default class AppConfigHandler {
  constructor(store) {
    this.$store = store
    this.currentConfig = {}
    this.isLoadingPermissionsFromApi = false
    this.isLoadingFromApi = false
    this.i18n = null
  }

  async init() {
    console.debug("init AppConfigHandler")
    this.currentConfig = JSON.parse(await Storage.get("config")) || {}

    console.debug("stored config", this.currentConfig)
    // load app config since app does not have it yet
    if (Object.keys(this.currentConfig).length == 0) {
      await this.loadFromApi()
    } else {
      // load up stored config
      this.processConfig(this.currentConfig)
      await this.loadFromApi()
    }
  }

  async loadFromApi() {
    if (this.isLoadingFromApi) { return }
    this.isLoadingFromApi = true
    console.debug("load app config from api")

    try {
      const { data } = await Api.config()

      this.processConfig(data.data.attributes)
      this.isLoadingFromApi = false
    } catch(e) {
      this.isLoadingFromApi = false
      console.log("config fetch error", e);
    }
  }

  // this should be called on every api response to check api version header
  // and determine if we need to update config
  check(apiConfigVersion) {
    console.debug("checking api header version", Number(apiConfigVersion), this.currentConfig.version)
    if (Object.keys(this.currentConfig).length == 0) { return }
    if (Number(apiConfigVersion) != this.currentConfig.version) {
      console.debug("dispatch load config from api")
      // load new config from api
      this.loadFromApi()
    }
  }

  // this should be called on every api response to check api version header
  // and determine if we need to update config
  checkPermissions(permissionsVersion) {
    console.debug("checking permissions header version", Number(permissionsVersion), this.$store.state.auth.permissionsVersion)
    if (Number(permissionsVersion) != this.$store.state.auth.permissionsVersion) {
      console.debug("dispatch load permissions from api")
      this.$store.dispatch("auth/fetchNewPermissions")
    }
  }

  async processConfig(data) {
    console.debug("process app config", data)
    this.currentConfig = data
    updateConfig(data);
    this.$store.dispatch("global/setAppConfig", data);

    if(this.i18n && data.custom_locale) {
      if(data.custom_locale.pt) {
        console.debug("updating locale pt")
        const merged = this.deepMerge(Object.assign({}, this.i18n.getLocaleMessage("pt")), data.custom_locale.pt)
        this.i18n.setLocaleMessage("pt", merged)
      }

      if(data.custom_locale.en) {
        console.debug("updating locale en")
        const merged = this.deepMerge(Object.assign({}, this.i18n.getLocaleMessage("en")), data.custom_locale.en)
        this.i18n.setLocaleMessage("en", merged)
      }
    }

    if (Capacitor.isPluginAvailable('StatusBar')) {
      if (data.color_hex) {
        StatusBar.setBackgroundColor({
          color: data.color_hex,
        });
      }
    }
  }

  setI18n(instance) {
    this.i18n =instance
  }

  deepMerge(target, source) {
    const isObject = (obj) => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = this.deepMerge(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }
}
