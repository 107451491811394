<template>
  <div class="bg-gray-100 h-12 rounded-lg p-1">
    <vue-horizontal class="horizontal" snap="none" :button="false">
      <div class="h-10 flex flex-row flex-1">
        <div class="whitespace-nowrap flex-1 flex-shrink-0 h-8 self-center justify-center text-gray-600 rounded-lg option relative flex items-center" v-for="option in options" :key="option.id" :class="{'active': value == option.id }" @click="$emit('input', option.id)">
          <div class="p-2">{{option.text}}</div>
        </div>
      </div>
    </vue-horizontal>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    options: {
      type: Array,
      default: function() {
        return []
      }
    },
    value: {}
  }
}
</script>

<style lang="scss" scoped>
  .option:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 50%;
    top: 25%;
    right: 0px;
    background-color: transparent;
    opacity: 0.24;
  }

  .option.active {
    @apply bg-white font-bold shadow-md text-gray-900;
  }
</style>
