export const global = {
  namespaced: true,
  state: {
    appConfig: {},
    setupComplete: false,
    loading: true,
    pushToken: null,
    pushTokenCommitted: false,
    api_url: null,
    store_domain: null,
    store_subdomain: null
  },
  getters: {
    setupComplete: (state) => state.setupComplete,
    categories: (state) => {
      const categories = (state.appConfig || {}).categories || [];

      return [...categories].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  actions: {
    setLoading({ commit }, value) {
      commit("setLoading", value);
    },
    setPushToken({ commit }, value) {
      commit("setPushToken", value);
    },
    setAppConfig({ commit }, data) {
      commit("setAppConfig", data);
    },
    clearStore({ commit }) {
      commit("clearStore");
    },
    setStore({ commit }, value) {
      commit("setStore", value);
    }
  },
  mutations: {
    setPushToken(state, value) {
      state.pushToken = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setStore(state, { api_url, store_domain, store_subdomain }) {
      state.api_url = api_url;
      state.store_domain = store_domain
      state.store_subdomain = store_subdomain
    },
    setAppConfig(state, data) {
      state.appConfig = data;
      state.setupComplete = true;
    },
    clearStore() {
      this.commit("jv/clearRecords", { _jv: { type: "order" } });
      this.commit("jv/clearRecords", { _jv: { type: "company" } });
      this.commit("jv/clearRecords", { _jv: { type: "communication" } });
      this.commit("jv/clearRecords", { _jv: { type: "product" } });
      this.commit("jv/clearRecords", { _jv: { type: "event" } });
      this.commit("jv/clearRecords", { _jv: { type: "variant" } });
      this.commit("jv/clearRecords", { _jv: { type: "cart" } });
      this.commit("jv/clearRecords", { _jv: { type: "shipment" } });
      this.commit("jv/clearRecords", { _jv: { type: "me" } });
      this.commit("jv/clearRecords", { _jv: { type: "comment" } });
      this.commit("jv/clearRecords", { _jv: { type: "loyalty_wallet" } });
    },
  },
};
