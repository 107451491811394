import Api from "@/api"

export const measurementCharts = {
  namespaced: true,
  state: {
    records: [],
    errors: [],
  },
  actions: {
    async fetch({ commit }, options = {}) {
      try {
        const { data } = await Api.getMeasurementCharts(options)
        commit("setRecords", data.data)
      } catch (e) {
        commit("setErrors", e)
      }
    },
  },
  mutations: {
    setRecords(state, data) {
      state.records = data
    },
    setErrors(state, data) {
      state.errors = data.message
    },
  },
}
