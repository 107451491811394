import Storage from "@/utils/storage"

export default async (attributes) => {
  if (attributes.color) {
    let root = document.documentElement;

    let color = attributes.color
    root.style.setProperty('--color', color.color);
    root.style.setProperty('--l', color.light);

    let color_light = attributes.color_light
    root.style.setProperty('--color-light', color_light.color);
    root.style.setProperty('--l-light', color_light.light);
  }

  await Storage.set("config", JSON.stringify(attributes))
}
