export const navbar = {
  namespaced: true,
  state: {
    root: true,
    title: "",
    cancelCallback: null,
    submitCallback: null,
    fallbackRoute: null,
    showBack: true
  },
  actions: {
    setRoot({ commit }, value) {
      commit("setRoot", value);
    },
    showBack({ commit }, value) {
      commit("showBack", value);
    },
    setTitle({ commit }, value) {
      commit("setTitle", value);
    },
    setFallbackRoute({ commit }, value) {
      commit("setFallbackRoute", value);
    },
    setCancelCallback({ commit }, value) {
      commit("setCancelCallback", value);
    },
    setSubmitCallback({ commit }, value) {
      commit("setSubmitCallback", value);
    },
  },
  mutations: {
    setRoot(state, value) {
      state.root = value;
    },
    showBack(state, value) {
      state.showBack = value;
    },
    setTitle(state, value) {
      state.title = value;
    },
    setFallbackRoute(state, value) {
      state.fallbackRoute = value;
    },
    setCancelCallback(state, value) {
      state.cancelCallback = value;
    },
    setSubmitCallback(state, value) {
      state.submitCallback = value;
    },
  },
};
