export default {
  computed: {
    currentUser() {
      if (!this.$store) { return null }

      return this.$store.state.auth.user
    },
    isAdmin() {
      if (!this.currentUser) { return false }

      return this.currentUser.admin === true
    }
  }
}
