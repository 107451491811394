import Vue from "vue";
import VueAxios from "vue-axios";
import qs from "qs";
import axiosInstance from "./axios";
import { auth } from "@/store/auth.module";
import { global } from "@/store/global.module";
import { Capacitor } from "@capacitor/core"
import { Example } from "plist-reader";
import Storage from "@/utils/storage"

const apiData = async () => {
  let isNativePlatform = false;

  try {
    isNativePlatform = Capacitor.isNativePlatform();
  } catch {
    isNativePlatform = false;
  }

  let api_url = null
  let store_domain = null
  let store_subdomain = null

  if (isNativePlatform) {
    const promise1 = Example.echo({ plist: "Info", key: "API_URL" })
    const promise2 = Example.echo({ plist: "Info", key: "STORE_DOMAIN" })
    const promise3 = Example.echo({ plist: "Info", key: "STORE_SUBDOMAIN" })

    const result = await Promise.all([promise1, promise2, promise3]).then((values) => {
      return {
        api_url: values[0].value,
        store_domain: values[1].value,
        store_subdomain: values[2].value
      }
    });

    api_url = result.api_url
    store_domain = result.store_domain
    store_subdomain = result.store_subdomain
  } else {
    api_url = process.env.VUE_APP_API_URL || "http://localhost:3000/api/v1";
    store_domain = process.env.VUE_APP_STORE_DOMAIN;
    store_subdomain = process.env.VUE_APP_STORE_SUBDOMAIN;
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (store_domain) {
    headers["X-Store-Domain"] = store_domain;
  }

  if (store_subdomain) {
    headers["X-Store-Subdomain"] = store_subdomain;
  }

  return {
    api_url: api_url,
    store_domain: store_domain,
    store_subdomain: store_subdomain,
    headers: headers
  }
}

let isRefreshing = false;
let refreshSubscribers = [];
// let refreshRetry = true;

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.map(cb => cb(token));
};

axiosInstance.interceptors.request.use(async (request) => {
  const token = await Storage.get("token")
  const apiDataResult = await apiData()

  if(token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  if (global.state.pushToken && !global.state.pushTokenCommitted) {
    const tokenHeader = `${Capacitor.getPlatform()}|${global.state.pushToken}`;
    request.headers["push-token"] = tokenHeader;
  }

  Object.entries(apiDataResult.headers).forEach((entry) => {
    request.headers[entry[0]] = entry[1]
  })

  request.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return request;
})

const responseHandler = (response) => {
  if (
    response &&
    !response.config.url.includes("refresh-token") &&
    response.headers["x-admin"] &&
    auth.state.user
  ) {
    const isAdmin = JSON.parse(response.headers["x-admin"]);

    if (auth.state.user.admin != isAdmin) {
      auth.state.user.admin = isAdmin;
    }
  }

  if (
    response &&
    !response.config.url.includes("refresh-token") &&
    response.headers["push-token-result"] &&
    !global.state.pushTokenCommitted
  ) {
    global.state.pushTokenCommitted = true;
  }

  if (
    response &&
    !response.config.url.includes("refresh-token") &&
    !response.config.url.includes("config") &&
    response.headers["x-app-config-version"]
  ) {
    Vue.prototype.$appConfigHandler.check(response.headers["x-app-config-version"])
  }

  if (
    response &&
    !response.config.url.includes("refresh-token") &&
    !response.config.url.includes("config") &&
    response.headers["x-app-permissions-version"]
  ) {
    Vue.prototype.$appConfigHandler.checkPermissions(response.headers["x-app-permissions-version"])
  }

  return response;
}

const refreshToken = async (token) => {
  try {
    const { data } = await axiosInstance.post("/refresh-token", { token: token })
    return data.data.attributes.jwt
  } catch(e) {
    console.log("error refresh token")
  }

  return null
}

const errorHandler = async (error) => {
  if (error?.response?.status === 401) {
    const originalRequest = error.config;
    const token = await Storage.get("token")

    const retryOrigReq = new Promise(resolve => {
      subscribeTokenRefresh((newToken) => {
        if (originalRequest.headers) {
          originalRequest.headers.authorization = `Bearer ${newToken}`;
        }
        resolve(axiosInstance(originalRequest));
      });
    });

    console.log("isRefreshing", isRefreshing, token)

    if (!isRefreshing && token) {
      isRefreshing = true;

      const newToken = await refreshToken(token)

      if (newToken && originalRequest.headers) {
        await Storage.set("token", newToken)
        originalRequest.headers.authorization = `Bearer ${newToken}`;
        onRefreshed(newToken);
      }

      if(!newToken) {
        console.log("could not refresh token")
        isRefreshing = false;
        const { default: store } = await import("@/store")
        await store.dispatch("auth/logout")
        return Promise.reject(error);
      }

      isRefreshing = false;
    }

    return retryOrigReq
  }

  return Promise.reject(error);
}

axiosInstance.interceptors.response.use(
  responseHandler,
  errorHandler
)


Vue.use(VueAxios, axiosInstance);

export default axiosInstance;
