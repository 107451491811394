"use strict"

function clone(item) {
  // clone our object, since valid source comes from a json this wont break.
  return JSON.parse(JSON.stringify(item))
}

function flattenIncluded(included, resource) {
  var object = resolveAttributes(included[resource.type][resource.id])
  var result = resolveRelationships(included, object)
  return result
}

function resolveData(included, data) {
  if (isIncluded(included, data)) {
    return flattenIncluded(included, data)
  } else {
    return null
  }
}

function resolveRelationshipData(included, links) {
  if (!links.data) {
    return null
  } else if (Array.isArray(links.data)) {
    return links.data
      .map(function(item) {
        return resolveData(included, item)
      })
      .filter(function(item) {
        return item !== null
      })
  } else {
    return resolveData(included, links.data)
  }
}

function isIncluded(included, resource) {
  return !(!included[resource.type] || !included[resource.type][resource.id])
}

function resolveRelationships(included, resource) {
  if (!resource.relationships) {
    return resource
  }

  Object.keys(resource.relationships).forEach(function(attribute) {
    resource[attribute] = resolveRelationshipData(
      included,
      resource.relationships[attribute]
    )
  })

  delete resource.relationships
  return resource
}

function resolveAttributes(resource) {
  resource = clone(resource)
  for (var name in resource.attributes) {
    resource[name] = resource.attributes[name]
  }

  delete resource.attributes
  return resource
}

function parseResource(included, item) {
  return resolveRelationships(included, resolveAttributes(item))
}

export default function(object) {
  var included = (object.included || []).reduce(function groupByType(
    result,
    resource
  ) {
    var collection = result[resource.type] || {}
    collection[resource.id] = resource
    result[resource.type] = collection
    return result
  },
  {})

  return object.data instanceof Array
    ? object.data.map(function(item) {
        return parseResource(included, item)
      })
    : parseResource(included, object.data)
}
