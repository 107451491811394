class Local {
  async set({ key, value }) {
    return new Promise(function (resolve) {
      localStorage.setItem(key, value)
      resolve()
    })
  }

  async get({ key }) {
    return new Promise(function (resolve) {
      const result = localStorage.getItem(key)
      resolve({ value: result })
    })
  }

  remove({ key }) {
    return localStorage.removeItem(key)
  }
}

export default new Local()
