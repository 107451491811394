<template>
  <div class="absolute inset-0 w-full h-full z-20" v-show="show">
    <div class="absolute inset-0 w-full h-full bg-gray-500 bg-opacity-40"></div>
    <div class="relative w-full h-full flex items-center flex-col">
      <div class="pt-20 w-full self-center px-4">
        <div class="bg-white rounded-lg p-4 w-full m-auto">
          <h2 class="text-primary font-semibold text-center">
            {{ title }}
          </h2>

          <div class="py-4">
            <slot name="body"></slot>
          </div>

          <div class="flex items-center space-x-2">
            <slot name="actions">
              <Button type="light" @click.prevent="onCancel">
                <span class="font-semibold">
                  {{ $t("app.cancel") }}
                </span>
              </Button>
              <Button @click.prevent="onConfirm" :loading="loading">
                <span class="font-semibold">
                  {{ $t("app.confirm") }}
                </span>
              </Button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button";

export default {
  name: "ConfirmationModal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Button
  },
  data() {
    return {}
  },
  methods: {
    onCancel() {
      this.$emit("cancel")
    },
    onConfirm() {
      this.$emit("confirm")
    }
  }
}
</script>
