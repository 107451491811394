import Api from "@/api"

export default class PushNotificationsHandler {
  constructor(router, store) {
    this.router = router
    this.store = store

    this.store.watch(
      () => this.store.getters["auth/isLoggedIn"],
      (isLoggedIn) => {
        if (isLoggedIn) {
          this.init()
        }
      },
      {
        immediate: true,
      }
    )
  }

  async init() {
    await this.fetch()
  }

  remove(id) {
    // optimistic approach here
    this.store.dispatch("notifications/removePushNotification", id)
    Api.removeNotification(id)
  }

  removeAll() {
    // optimistic approach here
    this.store.dispatch("notifications/removeAll")
    Api.removeAllNotifications()
  }

  async hide(id) {
    if (id) {
      await this.store.dispatch("notifications/hidePushNotification", id)
    }
  }

  async fetch(processId = null, show = false, navigate = false) {
    Api.fetchNotifications().then(({ data }) => {
      this.store.dispatch("notifications/setPushNotifications", data.data)

      if (processId) {
        const notification = this.store.getters[
          "notifications/pushNotifications"
        ].find((x) => x.id == processId)

        if (notification) {
          this.store.dispatch("notifications/showPushNotification", processId)
          notification.show = show
          if (show) {
            setTimeout(() => this.hide(processId), 4000)
          }
          this.process(notification.attributes.payload, navigate)
        }
      }
    })
  }

  process(data, navigate = false) {
    console.debug("PushNotificationsHandler: process data", data)
    const customData = data.data

    if (!customData) {
      return
    }

    if (customData.type != "navigate") {
      return
    }

    // do not naviate automatically
    if (navigate == false) {
      return
    }

    this.setFallbackRoute()
    this.navigate(customData)
  }

  navigate(data) {
    const object_type = data.object
    const id = data.id
    const subId = data.sub_id

    switch (object_type) {
      case "order":
        this.router.push({ name: "Order", params: { id: id } })
        break
      case "special_request":
        this.router.push({ name: "SpecialRequest", params: { id: id } })
        break
      case "event_registration":
        this.router.push({
          name: "EventRegistration",
          params: { id: id, sub: subId },
        })
        break
      case "prescription":
        this.router.push({ name: "Prescription", params: { id: id } })
        break
      case "event":
        this.router.push({ name: "Event", params: { id: id } })
        break
      case "communication":
        this.router.push({ name: "Communication", params: { id: id } })
        break
    }
  }

  setFallbackRoute() {
    this.store.dispatch("navbar/setFallbackRoute", {
      path: "/home",
    })
  }
}
