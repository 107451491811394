import { Realtime } from 'ably/promises';
import Api from "@/api";

export const realtime = {
  namespaced: true,
  state: {
    clientId: null,
    isConnected: false,
    client: null
  },
  actions: {
    initialize({ commit }) {
      commit('initialize')
    }
  },
  mutations: {
    initialize(state) {
      if (state.isConnected) { return Promise.resolve(state.client) }

      return new Promise((resolve, reject) => {
        const client = new Realtime({
          authCallback: async (tokenParams, callback) => {
            try {
              const { data } = await Api.realtimeToken()
              callback(null, data)
            } catch (error) {
              callback(error, null)
              reject(error)
            }
          },
          echoMessages: false,
        });

        client.connection.on('connected', () => {
          state.isConnected = true
          state.client = client
          state.clientId = client.auth.clientId
          resolve(state.client)
        });

        client.connection.on('disconnected', () => {
          state.isConnected = false
        });
      });
    },
  }
};
