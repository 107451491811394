import Api from "@/api"
import normalize from "@/utils/jsonapi-normalize"

export const measurementUsers = {
  namespaced: true,
  state: {
    records: [],
    record: {},
    errors: [],
    selectedRecord: {},
  },
  getters: {
    records: (state) => state.records,
  },
  actions: {
    async fetchMeasurementUsers({ commit, state }, options = {}) {
      try {
        const { data } = await Api.getMeasurementUsers(options)
        commit("setMeasurementUsers", normalize(data))

        if (Object.keys(state.selectedRecord).length === 0) {
          commit(
            "setSelectedRecord",
            data.data.find((el) => el.attributes.default == true)
          )
        }
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async fetchMeasurementUser({ commit }, id) {
      try {
        const { data } = await Api.getMeasurementUser(id)
        commit("setMeasurementUser", data.data)
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async createMeasurementUser({ commit }, payload) {
      try {
        const { data } = await Api.createMeasurementUser(payload)
        commit("addMeasurementUser", data.data)
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async updateMeasurementUser({ commit }, options = {}) {
      try {
        const { data } = await Api.updateMeasurementUser(
          options.id,
          options.payload
        )
        commit("setMeasurementUser", data.data)
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async destroyMeasurementUser({ commit }, id) {
      try {
        await Api.destroyMeasurementUser(id)
        commit("removeMeasurementUser", id)
      } catch (e) {
        commit("setErrors", e)
      }
    },
  },
  mutations: {
    setMeasurementUsers(state, data) {
      state.records = data
    },
    setMeasurementUser(state, data) {
      state.record = data
    },
    removeMeasurementUser(state, id) {
      state.records = state.records.filter((r) => r.id !== id)
      state.record = {}
    },
    addMeasurementUser(state, data) {
      let records = [...state.records, ...[data]]

      state.records = records
    },
    setSelectedRecord(state, data) {
      state.selectedRecord = data
    },
    setErrors(state, data) {
      state.errors = data.message
    },
  },
}
