import { LocalNotifications } from "@capacitor/local-notifications"
import store from "@/store"

class DosageNotificationService {
  async activate(record) {
    const notification = this.buildNotification(record)

    await LocalNotifications.schedule({
      notifications: [notification],
    })

    await this.updateActiveNotifications()
  }

  async cancel(record) {
    await LocalNotifications.cancel({
      notifications: [{ id: record.attributes.public_id }],
    })
    await this.updateActiveNotifications()
  }

  async updateActiveNotifications() {
    const { notifications } = await LocalNotifications.getPending()
    await store.dispatch("notifications/setActiveNotifications", notifications)
  }

  buildNotification(record) {
    const now = new Date()

    let str = `Está na altura de tomar a sua medicação. Tome ${record.attributes.name}`

    if (record.attributes.quantity && record.attributes.quantity.length > 0) {
      str += ` - ${record.attributes.quantity}`
    }

    str += " agora."

    return {
      title: "💊 Hora da medicação!",
      body: str,
      id: record.attributes.public_id,
      sound: "dosage.wav",
      schedule: {
        at: new Date(now.getTime() + 65 * 1000),
        repeats: true,
        allowWhileIdle: true,
      },
      extra: {
        id: record.id,
        type: "dosage_alert",
      },
    }
  }
}

export default new DosageNotificationService()
