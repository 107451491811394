<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :input-class="context.attributes.class"
    :data-type="context.type"
    :options="context.options"
    v-bind="context.attributes"
    @blur="context.blurHandler"
  >
    <textarea
      ref="textarea"
      :class="context.attributes.class"
      v-model="context.model"
      v-bind="context.attributes"
      v-on="$listeners"
      @blur="context.blurHandler"
    />
    <slot></slot>
  </div>
</template>

<script>
import autosize from "autosize"

export default {
  name: "VueFormulateResizableTextarea",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    autosize(this.$refs.textarea)
  },
}
</script>
<style scoped>
textarea {
  resize: none;
}
</style>
