async function uploadToS3(file, progress, error, options) {
  const matches = file.name.match(/\.([a-zA-Z0-9]+)$/)
  const extension = (matches) ? matches[1] : 'txt'
  const result = { url: null, name: null }

  progress(5)
  await options.presign({
    extension,
    mime: file.type || 'application/octet-stream'
  })
  .then(async response => {
    const data = response.data
    progress(10)
    const uploadUrl = data.url
    const xhr = new XMLHttpRequest()
    const formData = new FormData()

    xhr.open('POST', uploadUrl)
    xhr.upload.addEventListener('progress', e => progress(Math.round(e.loaded / e.total * 90) + 10))
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    var key = null
    for (key in data.fields) {
      formData.append(key, data.fields[key])
    }
    formData.append('file', file)

    try {
      await new Promise((resolve, reject) => {
        xhr.onload = () => (xhr.status - 200) < 100 ? resolve() : reject(new Error('Failed to upload'))
        xhr.onerror = () => reject(new Error('Failed to upload'))
        xhr.send(formData)
      })
      progress(100)
      const url = new URL(uploadUrl)
      result.url = `${url.protocol}//${url.host}${url.pathname}${data.fields.key}`
      result.name = data.fields.key.replace("cache/", "")
    } catch {
      error('There was an error uploading your file.')
    }
  })

  return result
}

export default uploadToS3;
