import Api from "@/api"
import normalize from "@/utils/jsonapi-normalize"

export const policies = {
  namespaced: true,
  state: {
    records: [],
  },
  getters: {
    records: (state) => state.records,
  },
  actions: {
    async fetch({ commit }) {
      try {
        const { data } = await Api.fetchPolicies()

        commit("setPolicies", normalize(data))
      } catch (e) {
        console.log("error", e)
      }
    },
  },
  mutations: {
    setPolicies(state, data) {
      state.records = data
    },
  },
}
