<template>
  <div class="circle"></div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style>
.circle {
  @apply border-primary-light border-2 w-6 h-6;
  box-sizing: border-box;
  border-radius: 100%;
  border-top-color: theme("colors.primary");
  animation: spin 0.7s infinite linear;
}
</style>
