<template>
  <div
    :class="`formulate-input-element bg-white rounded-lg formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <InputFileButton
      v-model="context.model"
      :options="context.options"
      :render-only-icon="renderOnlyIcon"
    />
  </div>
</template>

<script>
import InputFileButton from "@/components/InputFileButton"

export default {
  name: "File",
  components: {
    InputFileButton,
  },
  props: {
    renderOnlyIcon: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
