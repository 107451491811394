import { Preferences as backend } from '@capacitor/preferences';
import Local from '@/utils/storage-backends/Local';
import { Capacitor } from "@capacitor/core";

class Storage {
  constructor() {
    if (Capacitor.isPluginAvailable('Preferences')) {
      this.backend = backend
    } else {
      this.backend = Local
    }
  }

  async set(key, value) {
    return await this.backend.set({
      key: key,
      value: value,
    });
  }

  async get(key) {
    const { value } = await this.backend.get({ key: key })
    return value
  }

  remove(key) {
    return this.backend.remove({ key: key })
  }
}

export default new Storage()
