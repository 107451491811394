export const profile = {
  namespaced: true,
  state: {
    editProfile: false
  },
  actions: {
    toggleEditProfile({ commit }) {
      commit('toggleEditProfile')
    }
  },
  mutations: {
    toggleEditProfile(state) {
      state.editProfile = !state.editProfile
    },
  }
};
