import Vue from "vue"
import Api from "@/api"

export const notifications = {
  namespaced: true,
  state: {
    notifications: [],
    pushNotifications: [],
    modalNotifications: [],
    activeNotifications: [],
  },
  getters: {
    pushNotifications: (state) => {
      return state.pushNotifications.sort((a, b) => {
        let aDate = a.attributes.created_at
        let bDate = b.attributes.created_at

        if (!(aDate instanceof Date)) {
          aDate = new Date(a.attributes.created_at)
        }

        if (!(bDate instanceof Date)) {
          bDate = new Date(b.attributes.created_at)
        }

        return Number(bDate) - Number(aDate)
      })
    },
    showNotifications: (state) => {
      return state.pushNotifications.filter((x) => x.show)
    },
    modalNotifications: (state) => state.modalNotifications,
  },
  actions: {
    setPushNotifications({ commit }, data) {
      commit("setPushNotifications", data)
    },
    addPushNotification({ commit }, data) {
      commit("addPushNotification", data)
    },
    showPushNotification({ commit }, id) {
      commit("showPushNotification", id)
    },
    hidePushNotification({ commit }, id) {
      commit("hidePushNotification", id)
    },
    removePushNotification({ commit }, id) {
      commit("removePushNotification", id)
    },
    async addModalNotification({ commit }, data) {
      const { data: dosage } = await Api.loadDosage(data.id)
      commit("addModalNotification", {
        ...dosage.data,
        notification_type: data.type,
      })
    },
    removeModalNotification({ commit }, id) {
      commit("removeModalNotification", id)
    },
    removeAll({ commit }) {
      commit("removeAll")
    },
    displayNotification({ commit, dispatch }, notification) {
      commit("displayNotification", notification)
      setTimeout(() => dispatch("removeNotification", notification), 2000)
    },
    removeNotification({ commit }, notification) {
      commit("removeNotification", notification)
    },
    setActiveNotifications({ commit }, data) {
      commit("setActiveNotifications", data)
    },
  },
  mutations: {
    displayNotification(state, notification) {
      state.notifications = [notification]
    },
    removeNotification(state, notification) {
      const notifications = state.notifications.filter(
        (x) => x.id != notification.id
      )
      state.notifications = notifications
    },
    removeAll(state) {
      state.pushNotifications = []
    },
    hidePushNotification(state, id) {
      const existsAtIndex = state.pushNotifications.findIndex(
        (u) => u.id === id
      )

      if (existsAtIndex > -1) {
        state.pushNotifications[existsAtIndex].show = false
        Vue.set(
          state.pushNotifications,
          existsAtIndex,
          state.pushNotifications[existsAtIndex]
        )
      }
    },
    showPushNotification(state, id) {
      const existsAtIndex = state.pushNotifications.findIndex(
        (u) => u.id === id
      )

      if (existsAtIndex > -1) {
        state.pushNotifications[existsAtIndex].show = true
        Vue.set(
          state.pushNotifications,
          existsAtIndex,
          state.pushNotifications[existsAtIndex]
        )
      }
    },
    async addPushNotification(state, data) {
      state.pushNotifications.push(data)
    },
    async removePushNotification(state, id) {
      state.pushNotifications = state.pushNotifications.filter(
        (item) => item.id !== id
      )
    },
    setPushNotifications(state, data) {
      state.pushNotifications = data
    },
    addModalNotification(state, data) {
      const existsAtIndex = state.modalNotifications.findIndex(
        (u) => u.id === data.id
      )
      if (existsAtIndex > -1) {
        Vue.set(state.modalNotifications, existsAtIndex, data)
      } else {
        state.modalNotifications.push(data)
      }
    },
    removeModalNotification(state, id) {
      state.modalNotifications = state.modalNotifications.filter(
        (item) => item.id !== id
      )
    },
    setActiveNotifications(state, data) {
      state.activeNotifications = data
    },
  },
}
