<template>
  <FormulateForm name="form" v-model="form" @submit="onSubmit" #default="{ isValid }" :errors="inputErrors">
    <Modal @cancel="onCancel" :title="$t('title.profile')" :showSubmit="false">
      <div class="w-full h-full flex items-center justify-center" v-if="submitingDeleteRequest">
        <Loading />
      </div>

      <ConfirmationModal :show="showDeleteConfirmation" @cancel="showDeleteConfirmation = false"
        @confirm="deleteAccount" title="Eliminar Conta" :loading="submitingDeleteRequest">
        <template #body>
          <div class="w-full text-center">
            Tem a certeza que deseja eliminar a sua conta?

            <div class="py-6">
              <div class="text-sm text-gray-500 leading-tight">
                Este procedimento irá apagar todas as suas informações e deixará de ter acesso às suas compras, pedidos
                entre outras informações.
              </div>
            </div>
          </div>
        </template>
      </ConfirmationModal>
      <div class="bg-white h-full p-5 overflow-auto pb-16 pt-5">
        <div v-if="loading" class="pt-16 flex justify-center">
          <Loading />
        </div>
        <div v-if="!loading && user">
          <div class="flex relative flex-col items-center">
            <Upload v-model="form.photo">
              <div v-if="!form.photo || form.photo.url == null">
                <span class="text-gray-400">
                  <svg width="96" height="96" viewBox="0 0 96 96" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M48 0C21.504 0 0 21.504 0 48s21.504 48 48 48 48-21.504 48-48S74.496 0 48 0zM24.336 78.144C26.4 73.824 38.976 69.6 48 69.6c9.024 0 21.648 4.224 23.664 8.544C65.136 83.328 56.928 86.4 48 86.4s-17.136-3.072-23.664-8.256zm54.192-6.96C71.664 62.832 55.008 60 48 60c-7.008 0-23.664 2.832-30.528 11.184C12.576 64.752 9.6 56.736 9.6 48 9.6 26.832 26.832 9.6 48 9.6S86.4 26.832 86.4 48c0 8.736-2.976 16.752-7.872 23.184zM48 19.2c-9.312 0-16.8 7.488-16.8 16.8 0 9.312 7.488 16.8 16.8 16.8 9.312 0 16.8-7.488 16.8-16.8 0-9.312-7.488-16.8-16.8-16.8zm0 24a7.19 7.19 0 01-7.2-7.2 7.19 7.19 0 017.2-7.2 7.19 7.19 0 017.2 7.2 7.19 7.19 0 01-7.2 7.2z"
                      fill="currentColor" />
                  </svg>
                </span>
              </div>
              <div v-if="form.photo && form.photo.url">
                <img class="w-24 h-24 rounded-full object-cover" :src="form.photo.url" alt="User Profile Image" />
              </div>
              <div class="absolute text-primary bottom-0 right-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1618_10041)">
                    <path
                      d="M12 24C15.1826 24 18.2349 22.7357 20.4853 20.4853C22.7357 18.2349 24 15.1826 24 12C24 8.81743 22.7357 5.76514 20.4853 3.51472C18.2349 1.26428 15.1826 0 12 0C8.81743 0 5.76514 1.26428 3.51472 3.51472C1.26428 5.76514 0 8.81743 0 12C0 15.1826 1.26428 18.2349 3.51472 20.4853C5.76514 22.7357 8.81743 24 12 24Z"
                      fill="#F9FAFB" />
                    <path class="fill-current" fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.5 7.57143C7.10218 7.57143 6.72064 7.73699 6.43934 8.03169C6.15804 8.32639 6 8.72609 6 9.14286V15.4286C6 15.8453 6.15804 16.245 6.43934 16.5397C6.72064 16.8344 7.10218 17 7.5 17H16.5C16.8978 17 17.2794 16.8344 17.5607 16.5397C17.842 16.245 18 15.8453 18 15.4286V9.14286C18 8.72609 17.842 8.32639 17.5607 8.03169C17.2794 7.73699 16.8978 7.57143 16.5 7.57143H15.3105C15.1116 7.57138 14.9209 7.48857 14.7802 7.34121L13.9395 6.46043C13.6583 6.16571 13.2768 6.00009 12.879 6H11.121C10.7232 6.00009 10.3417 6.16571 10.0605 6.46043L9.21975 7.34121C9.07913 7.48857 8.8884 7.57138 8.6895 7.57143H7.5ZM12 14.6429C12.2955 14.6429 12.5881 14.5819 12.861 14.4634C13.134 14.345 13.3821 14.1713 13.591 13.9525C13.7999 13.7336 13.9657 13.4737 14.0787 13.1878C14.1918 12.9018 14.25 12.5953 14.25 12.2857C14.25 11.9762 14.1918 11.6697 14.0787 11.3837C13.9657 11.0977 13.7999 10.8378 13.591 10.619C13.3821 10.4001 13.134 10.2265 12.861 10.108C12.5881 9.98954 12.2955 9.92857 12 9.92857C11.4033 9.92857 10.831 10.1769 10.409 10.619C9.98705 11.061 9.75 11.6606 9.75 12.2857C9.75 12.9109 9.98705 13.5104 10.409 13.9525C10.831 14.3945 11.4033 14.6429 12 14.6429V14.6429Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1618_10041">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </Upload>
            <div class="mt-4 text-lg font-semibold">
              {{ user.user_profile.name }}
            </div>
            <div class="text-xs text-gray-600 mt-px">{{ user.email }}</div>
          </div>

          <div class="form-group mt-4 flex flex-col">
            <FormulateInput name="name" type="text" :label="$t('label.name')" validation="required|not:number"
              localeName="Nome" />
          </div>
          <div class="form-group flex flex-col">
            <FormulateInput name="mobile_number" type="number" :label="$t('label.contact')"
              :validation="[['matches', /^(2|9)[0-9]{8}$/]]" :validation-messages="{
                matches: $t('matches.9phone'),
              }" />
          </div>
          <div class="form-group flex flex-col">
            <FormulateInput name="nif" type="number" :label="$t('label.nif')" validation="matches:/^\d{9}$/"
              :validation-messages="{
                matches: $t('matches.9nif'),
              }" />
          </div>
          <div class="form-group flex flex-col">
            <FormulateInput name="birth_date" type="datepicker" :label="$t('label.birthday')" validation="date"
              :validation-messages="{
                date: $t('app.required_date'),
              }" />
          </div>
          <div class="form-group flex flex-col">
            <FormulateInput :options="gender.options" name="gender" type="radio" :label="$t('label.gender')"
              validation="required" :validation-messages="{
                required: $t('app.required_field'),
              }" />
          </div>
          <div class="py-4">
            <Button :type="!isValid ? 'light' : ''" :disabled="!isValid" :loading="updateLoading">
              <span class="font-semibold">
                {{ $t("app.update") }}
              </span>
            </Button>
            <Button type="link" class="mt-10" @click="onDeleteAccount">
              <span class="font-semibold text-sm text-red-400">
                {{ $t("app.delete_account") }}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  </FormulateForm>
</template>

<script>
import Api from "@/api";
import Upload from "@/components/Upload";
import Loading from "@/components/Loading";
import Button from "@/components/Button";
import ConfirmationModal from "@/components/ConfirmationModal";

export default {
  name: "EditProfile",
  components: {
    Upload,
    Loading,
    Button,
    ConfirmationModal
  },
  data() {
    return {
      inputErrors: {},
      updateLoading: false,
      loading: false,
      showDeleteConfirmation: false,
      submitingDeleteRequest: false,
      gender: {
        options: [
          {
            id: "female",
            text: this.$t("app.user_female"),
          },
          {
            id: "male",
            text: this.$t("app.user_male"),
          },
        ],
      },
      form: {
        name: null,
        nif: null,
        mobile_number: null,
        birth_date: null,
        gender: null,
        photo: null,
      },
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    user_profile: {
      immediate: true,
      handler: function () {
        this.updateForm();
      },
    },
  },
  methods: {
    updateForm() {
      if (Object.keys(this.user_profile).length > 0) {
        const formProperties = Object.keys(this.form);
        this.form = this.$pick(this.user.user_profile, formProperties);
      }
    },
    fetchData() {
      this.loading = true;
      const payload = { include: ["user_profile"] };
      this.$store
        .dispatch("jv/get", ["me", { params: payload }])
        .then(() => (this.loading = false))
        .catch(() => (this.loading = false));
    },
    onCancel() {
      this.$emit("cancel");
    },
    onSubmit(data) {
      this.updateLoading = true;
      Api.updateUserProfile(data)
        .then((response) => {
          this.updateLoading = false;
          const data = response.data.data;
          const userData = Object.assign(this.currentUser, data.attributes);

          const formProperties = Object.keys(this.form);
          this.form = this.$pick(data.attributes, formProperties);

          this.$store.dispatch("notifications/displayNotification", {
            text: this.$t("app.profile_updated"),
          });

          this.$store.dispatch("auth/setUser", userData);
          this.fetchData();

          this.$emit("cancel");
        })
        .catch((err) => {
          this.updateLoading = false;

          this.inputErrors = err.response.data.errors;
        });
    },
    onDeleteAccount(e) {
      e.preventDefault()
      e.stopPropagation()

      this.showDeleteConfirmation = true
    },
    deleteAccount() {
      this.submitingDeleteRequest = true

      Api.deleteAccount()
        .then(() => {
          this.$store.dispatch("auth/logout")
          location.reload()
        })
        .catch(() => {
          this.submitingDeleteRequest = false
          this.showDeleteConfirmation = false;
        });
    }
  },
  computed: {
    user: {
      get() {
        return Object.values(this.$store.getters["jv/get"]("me"))[0];
      },
    },
    user_profile: {
      get() {
        if (this.user && this.user.user_profile) {
          return this.user.user_profile;
        }

        return {};
      },
    },
  },
};
</script>
