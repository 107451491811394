import Api from "@/api"
import normalize from "@/utils/jsonapi-normalize"

export const consents = {
  namespaced: true,
  state: {
    records: [],
    record: {},
    errors: [],
  },
  getters: {
    records: (state) => state.records,
  },
  actions: {
    async fetch({ commit }, options = {}) {
      try {
        const { data } = await Api.fetchUserConsents(options)

        commit("setConsents", normalize(data))
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async create({ commit }, payload) {
      try {
        await Api.createUserConsent(payload)
      } catch (e) {
        commit("setErrors", e)
      }
    },
    async destroy({ commit }, id) {
      try {
        await Api.destroyUserConsent(id)
      } catch (e) {
        commit("setErrors", e)
      }
    },
  },
  mutations: {
    setConsents(state, data) {
      state.records = data
    },
    setConsent(state, data) {
      state.record = data
    },
    setErrors(state, data) {
      state.errors = data.message
    },
  },
}
