<template>
  <div class="h-11 flex rounded-lg hover:border-primary" v-bind:class="classes">
    <label
      class="cursor-pointer w-full flex items-center justify-between px-4 py-3"
    >
      <span v-if="!renderOnlyIcon" class="leading-normal text-gray-400"
        >{{ $t("app.search") }}...</span
      >
      <svg
        width="21"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M.2 2.8A2.6 2.6 0 012.8.2h6.5l2.6 2.6h6.5A2.6 2.6 0 0121 5.4v7.8a2.6 2.6 0 01-2.6 2.6H2.8a2.6 2.6 0 01-2.6-2.6V2.8zM8 9.3h5.2H8zm2.6-2.6v5.2-5.2z"
          fill="#D1D5DB"
        />
        <path
          d="M10.6 6.7v5.2M8 9.3h5.2H8z"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <input type="file" class="hidden" />
    </label>
  </div>
</template>

<script>
export default {
  name: "InputFileButton",
  props: {
    renderOnlyIcon: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return this.renderOnlyIcon
        ? "bg-gray-100"
        : "border border-gray-200 shadow-sm"
    },
  },
}
</script>

<style lang="scss" scoped></style>
