import { render, staticRenderFns } from "./RadioButton.vue?vue&type=template&id=1fef6b86&scoped=true"
import script from "./RadioButton.vue?vue&type=script&lang=js"
export * from "./RadioButton.vue?vue&type=script&lang=js"
import style0 from "./RadioButton.vue?vue&type=style&index=0&id=1fef6b86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fef6b86",
  null
  
)

export default component.exports