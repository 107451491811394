<template>
  <div>
    <div
      class="noti-container fixed top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-blur-sm bg-gray-500 bg-opacity-50"
      v-if="showNotifications.length" @click="clickOutside">
      <div class="block mt-6 px-4" v-for="notification in showNotifications" :key="notification.id"
        @click="navigate($event, notification)">
        <div class="bg-primary-light px-4 py-5 rounded-md text-gray-900 shadow-lg">
          <div class="h-16 overflow-hidden flex flex-col">
            <span class="font-semibold">{{ notification.attributes.payload.data.title }}</span>
            <p class="text-sm text-gray-600 mt-1 line-clamp-2 flex-1">
              {{ notification.attributes.payload.data.body }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-for="notification in notifications" :key="notification.id">
      <Notification :text="notification.text" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Notification from "@/components/Notification"
import { mapState, mapGetters } from 'vuex'

export default {
  name: "NotificationsManager",
  components: {
    Notification
  },
  computed: {
    ...mapState('notifications', ['notifications']),
    ...mapGetters('notifications', ['showNotifications']),
  },
  methods: {
    navigate(e, data) {
      e.preventDefault()
      e.stopPropagation()

      this.$store.dispatch("notifications/hidePushNotification", data.id)
      Vue.prototype.$notificationHandler.process(data.attributes.payload, true)
    },
    clickOutside(e) {
      e.preventDefault()
      e.stopPropagation()

      this.showNotifications.forEach(x => this.$store.dispatch("notifications/hidePushNotification", x.id))
    }
  }
}
</script>

<style scoped>
.noti-container {
  padding-top: var(--safe-area-inset-top);
}
</style>
