import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3000/api/v1";
const STORE_DOMAIN = process.env.VUE_APP_STORE_DOMAIN;
const STORE_SUBDOMAIN = process.env.VUE_APP_STORE_SUBDOMAIN;

const headers = {
  "Content-Type": "application/json",
};

if (STORE_DOMAIN) {
  headers["X-Store-Domain"] = STORE_DOMAIN;
}

if (STORE_SUBDOMAIN) {
  headers["X-Store-Subdomain"] = STORE_SUBDOMAIN;
}

export default axios.create({
  baseURL: API_URL,
  headers: headers
});
