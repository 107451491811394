import Vue from "vue"
import Vuex from "vuex"
import { jsonapiModule } from "jsonapi-vuex"
import api from "@/api/base"
import { auth } from "./auth.module"
import { navbar } from "./navbar.module"
import { profile } from "./profile.module"
import { notifications } from "./notifications.module"
import { global } from "./global.module"
import { communications } from "./communications.module"
import { realtime } from "./realtime.module"
import { dosages } from "./dosages.module"
import { measurementUsers } from "./measurement-users.module"
import { measurementCharts } from "./measurement-charts.module"
import { policies } from "./policies.module"
import { consents } from "./consents.module"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    navbar,
    profile,
    notifications,
    jv: jsonapiModule(api, { preserveJson: true, mergeRecords: true }),
    global,
    communications,
    realtime,
    dosages,
    measurementUsers,
    measurementCharts,
    policies,
    consents,
  },
})
