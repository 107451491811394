import Storage from "@/utils/storage"

export default {
  methods: {
    async setCartItemNumber(number) {
      await Storage.set("cartItems", number.toString());
      this.dispatchEvent();
    },
    async dispatchEvent() {
      const cartItems = await Storage.get("cartItems") || 0

      window.dispatchEvent(
        new CustomEvent("localStorageCartItemsChanged", {
          detail: {
            cartItems: Number(cartItems),
          },
        })
      );
    },
    async forceCartItemDisplay(quantity) {
      const cartItems = await Storage.get("cartItems")

      if (
        cartItems == undefined || cartItems == null || cartItems != quantity
      ) {
        await Storage.set("cartItems", quantity);
        this.dispatchEvent();
      }
    },
  },
};
