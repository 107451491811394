
<template>
  <div class="flex flex-col items-center justify-center blured_background rounded-md p-4 shadow-sm z-50">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M5 13l4 4L19 7"/></svg>
    <slot>{{text}}</slot>
  </div>
</template>

<script>

export default {
  name: "Notification",
  props: {
    id: {
      type: String,
      default: '_' + Math.random().toString(36).substr(2, 9)
    },
    text: {
      type: String
    }
  }
}
</script>

<style>
  .blured_background{
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(156, 155, 155, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    width: 175px;
    height:175px;
    margin-top: -87.5px;
    margin-left: -87.5px;
    text-align:center;
  }
</style>
