<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <RadioButton v-model="context.model" :options="context.options" />
  </div>
</template>

<script>
import RadioButton from "@/components/RadioButton"

export default {
  name: "RadioInput",
  components: {
    RadioButton
  },
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  data () {
    return {}
  }
}
</script>

<style>

</style>
