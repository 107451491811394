export default {
  methods: {
    noAvatarDisplay(user) {
      if (user?.name == undefined && user?.email == undefined) {
        return;
      }

      if (user?.name == undefined) {
        const str = user.email.split("@")[0].toUpperCase();

        return str[0] + str.slice(-1);
      }

      const nameArray = user.name.split(" ");
      let str = "";

      if (nameArray.length > 1) {
        str += nameArray[0].charAt(0).toUpperCase();
        str += nameArray[nameArray.length - 1].charAt(0).toUpperCase();
        return str;
      }
      if (nameArray.length == 1) {
        return nameArray[0].charAt(0).toUpperCase();
      } else {
        return "";
      }
    },
  },
};
