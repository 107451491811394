export default {
  methods: {
    userFirstName(user) {
      if (user?.name == undefined) {
        return "Utilizador";
      }

      return user.name.split(" ")[0];
    },
  },
};
