<template>
  <div>
    <component v-for="notif of modalNotifications" :key="notif.id" :is="notif.notification_type" :object="notif" @close="removeNotification(notif.id)"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dosage from "./notifications/Dosage"
import DosageCreate from "./notifications/DosageCreate.vue"

export default {
  name: "ModalManager",
  components: {
    'dosage_alert': Dosage,
    'dosage_create': DosageCreate
  },
  computed: {
    ...mapGetters('notifications', ['modalNotifications']),
  },
  methods: {
    removeNotification(id) {
      this.$store.dispatch("notifications/removeModalNotification", id)
    }
  }
}
</script>

<style scoped>
</style>
