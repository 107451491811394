import Api from "@/api"

export const dosages = {
  namespaced: true,
  state: {
    records: []
  },
  getters: {
    records: (state) => state.records
  },
  actions: {
    async fetchDosages({ commit }) {
      try {
        const { data } = await Api.getDosages()
        commit('setDosages', data.data)
      } catch(e) {
        console.log("error", e)
      }

    }
  },
  mutations: {
    setDosages(state, data) {
      state.records = data
    }
  }
};
